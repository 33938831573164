import { Injectable, inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IBasketItemDto, ITeacherTeachingLanguageDto, ITeachingLanguageDto } from '@GeneratedTsFiles/index';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  router = inject(Router);
  generalService = inject(GeneralService);
  constructor() { }

  navigateToCheckoutStep(nextStep: string, extras?: NavigationExtras) {
    this.router.navigate(['checkout', nextStep], {
      ...extras
    });
    // Navigate to the next step route with optional navigation extras
  }

  goToCheckoutStep(step: number) {
    let routePath: string;

    switch (step) {
      case 1:
        routePath = "/buy-package/checkout/cart-details";
        break;
      case 2:
        routePath = "/buy-package/checkout/personal-info";
        break;
      case 3:
        routePath = "/buy-package/checkout/completed-payment";
        break;
      default:
        routePath = "/";
        break;
    }

    this.router.navigate([routePath]);
  }

/**
 * The function `buildBasketItemTitle` constructs a title for a basket item based on the product
 * details and optional teaching language.
 * @param {IBasketItemDto} product - IBasketItemDto - an interface representing a product in the basket
 * with properties like teachingLanguageName, numberOfLessons, levelEnum, and durationInMinutes.
 * @param {ITeachingLanguageDto} [teachingLanguage] - The `teachingLanguage` parameter is an optional
 * parameter of type `ITeachingLanguageDto`. It is used to specify the language in which the product is
 * taught. If provided, it will be used to include the language name in the product title. If not
 * provided, the `teachingLanguage
 * @returns The function `buildBasketItemTitle` returns a string that includes the teaching language
 * name (if provided), the number of lessons, the language level, and the duration in minutes of the
 * product.
 */
  buildBasketItemTitle(product: IBasketItemDto, teachingLanguage?: ITeachingLanguageDto) {
    const productTitle = product.numberOfLessons + ' ' + (teachingLanguage ? teachingLanguage.name : product.teachingLanguageName) + ' Lessons ' + this.generalService.getILanguageLevelsEnumText(product.levelEnum!) + ' ' + product.durationInMinutes + ' minutes';
    return productTitle;
  }

}
